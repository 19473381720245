<template>
  <view-container name="terms">
    <view-header>
      <custom-header router-back="/settings/info">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />{{ $t('pages.settings.terms') }}
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div class="terms-content" v-html="content"></div>
    </view-content>
  </view-container>
</template>

<script>
import { getPage } from '@/api/public/page'

export default {
  name: 'Terms',
  data() {
    return {
      page: null
    }
  },
  computed: {
    content() {
      return this.page?.description || null
    }
  },
  async created () {
    try {
      const { data } = await getPage('terms')

      this.page = data
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.terms-content {
  line-height: 1.5;
  font-size: 15px;
  text-align: justify;
}
</style>
